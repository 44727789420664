import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const ArticleTypeSEO = () => (
  <Layout>
    <SEO
      title="The Best Article Type For SEO"
      description="When there’s so much competition out there, it always helps to niche down on a subject if you want to get noticed. Writing a general article on lots of different ideas won’t be as focussed as one that takes a topic and explains it really well"
    />
    <h1>The Best Article Type For SEO</h1>
    <p>I have been a bit deceiving in the title because the truth is, there is no single best article for SEO. SEO covers a range of topics, it is a list of components and when it comes to article types, we need a few to fit together to reach our goal.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/uR7gwS2.jpg"
        alt="The Best Article Type For SEO"
        description="The Best Article Type For SEO"
      />
    </div>
    <p>
      Photo by{" "} 
      <a
        href="https://unsplash.com/@ohlrogge"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      Niklas Ohlrogge
      </a>
      {" "} on Unsplash
    </p>

    <p>If you are searching for the best article type then by the end of this post, you will be able to understand why that is the wrong question to ask. Instead you will understand what types (multiple) of articles you should be writing to get the results you want.</p>
    
    <h2>Types of Visitor</h2>
    <p>The first thing to understand is that each person who could become a future customer is on a different stage of the sales journey. With regards to SEO and to keep it simple, the two you should be thinking about are:-</p>

    <h2>People who know they have a problem but don’t know what the solution is</h2>
    <p>These types of people will be searching for keywords that help them understand their problem. They will be more suited to informational articles. They don’t even know there is a way to solve their problem so trying to sell a solution at this point would be useless.</p>
    <p>If you don’t cater to these people, you are leaving them to your competition who is happy to help them at this stage. If you only have sales type articles (which we’ll speak about next), you will only have access to people who are looking for a solution.</p>
    <p>Understanding why more than one type of article might be clear to you now and I’m sure most of you can stop reading now. Keep reading though because there are a lot more nuggets ahead that will give you a fill picture. You might not even need to hire me anymore to get the job done.</p>

    <h2>People who know they have a problem know what the solution is</h2>
    <p>When most clients think of SEO, this is what they think of. How can I get people to my site to convert? For the lead to be at the stage where they are ready to buy something, they are usually well educated on the solution and are fairly certain of what they want.</p>
    <p>As a business, you can easily get away with only producing content for this level of customer. The reason is that other businesses will have done the warming up and educating for you. But for some reason, their solution made the lead hesitate and shop around. Your solution ended up looking better in some way so they went with you.</p>
    <p>The problem here, as I’ve mentioned in the last section, is that you are potentially missing out on a lot of business. If you have a great solution, well done. Now, combine a great solution with great education and you are ready to take a whole lot more of the market share. That’s where informational articles become useful.</p>
    <p>Once a lead understands the problem and the ideal solution is right in front of them, do they even need to shop around? Some people will, yes. But, a lot of people will convert on the spot.</p>

    <h2>Think about the funnel</h2>
    <p>You should have the idea that more than one piece of content is required to convert a range of people at different stages of the buying journey. You can visualise it as a flow from stage to stage where the person learns more as they go on until they reach the end.</p>
    <p>This flow process is called a sales funnel. Each business will have a funnel that looks different but will follow the same idea, educate and get the sale. Don’t think that every visitor has to start from the beginning either. Like I said before, other businesses will have done some of the work for you, which is how you’ve been seeing success already.</p>
    <p>By working on your funnel, you are increasing the chances of leads converting with you. That’s because you have given them trust in your product or service by telling them exactly what they want to know.</p>
    <p>When you are designing a funnel, think about every stage of the customer journey, what the person should learn at that stage and what content will fit. Your customers will be drawn to different things, some will prefer stories, others reviews, others will enjoy long form content and others short. It’s a good idea to experiment to figure out what converts best.</p>
    <p>You might be lucky and find what works straight away or you might have to have patience and wait to see results. Either way you are learning about what works and what doesn’t. If you already have a lot of customers and sales, don’t be afraid to interview them to help you figure out why they converted so you can use that information.</p>

    <h2>Informational article</h2>
    <p>The purpose of an information article is to educate the lead on topics around your business and the problems that they need solving. You should stay away from selling them your product or service and help them reach that conclusion themselves. At first they might not even know you provide that solution at all.</p>
    <p>You should come across as a friendly voice that is trying to help them without asking for anything in return. By the time they have read all of your informational content, they will be in a place to make a decision.</p>
    <p>Don’t get me wrong, you don’t want to prime them to go elsewhere. There are a few ways you can get them ready to purchase from you, by listing solutions that you have a clear advantage in or only you provide. That way when it’s time to spend, they will be more likely to go with you if they value that part of the problem highly.</p>

    <h2>Sales article</h2>
    <p>The sales article needs to sell them on the idea of buying from you. There are all sorts of techniques you can employ here but the idea is to appear fair and not trash other solutions. I have seen comparison tables work well where you list your main competitors and highlight the things you do best.</p>
    <p>Then there’s reviews from actual customers that do well and act as social proof. If someone else has bought from you and had a good experience, that takes away some of the anxiety of purchasing something they haven’t tried before.</p>

    <h2>Why more than one article, why not all in one?</h2>
    <p>Why not have all the content in one article is a valid question and seems logical and while it can be done, there are two very good reasons not to do it. They are to make a manageable amount of content for readers and for SEO purposes.</p>

    <h2>Manageable amount</h2>
    <p>Taking the reader through your entire sales funnel on one page can be time consuming. They will have so much content to read if you go into any kind of depth. Then there’s the people that are at different stages of the sales journey. The ones who already know about the solution and don’t need to be educated around the problem will be revising information they already know.</p>
    <p>That could be frustrating and they will end up skipping ahead along the page. Once people start skipping, it’s very difficult to get them to stop and they might end up skipping away from your page and onto someone else’s.</p>
    <p>There’s nothing wrong with giving people information in pieces. They can read everything they need to know in a short time but when it’s broken up into different pieces of content, it makes it so much easier to get through.</p>

    <h2>SEO</h2>
    <p>When there’s so much competition out there, it always helps to niche down on a subject if you want to get noticed. Writing a general article on lots of different ideas won’t be as focussed as one that takes a topic and explains it really well.</p>
    <p>If you cover a topic well, especially with unique content and viewpoints then a search engine should reward you.</p>


    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default ArticleTypeSEO
